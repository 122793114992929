<template>
  <div class="box">
    <div class="boxCenter clearfix">
      <div class="company fl">
        <div class="title" >
            <div class="topBox">
                <span>推荐服务与成员</span>
            </div>
            <div class="cursor more" @click="handleCenterMore">
                <span class="moreFont">更多</span>
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <div v-loading="centerLoading" style="height:280px">
          <div class="list">
            <div class="item_newPeople" v-for="(item, index) in centerList" :key="index">
              <div class="img">
                <el-image
                  style=""
                  :src="item.logo?item.logo:defaultLogo"
                  fit="contain">
                  </el-image>
              </div>
              <div class="right_con">
                  <span class="company cursor textOverflow" @click="goCompanyDetail(item)">{{ item.company_name_en | priorFormat(item.company_name_cn, LOCALE) }}</span>
                  <div>
                    <span class="dizhi textOverflow">{{ item.country | countryCityFormat(item.region_en, item.region_zh, LOCALE) }}</span>
                    <span class="time fr textOverflow">{{ item.created_time | secondFormat("LL") }}</span>
                  </div>
                  <div class="tags" v-if="item.business_classification_list&&item.business_classification_list.length>0">
                    <el-tag type="info" size="small" class="item" v-for="(li,index) in item.business_classification_list" :key="index">
                      {{li.desc_en|priorFormat(li.desc_zh,LOCALE)}}
                    </el-tag>
                  </div>
              </div>
            </div>
            <noDataImg style="margin-top: 120px;" v-if="!centerLoading && centerList.length === 0"></noDataImg>
          </div>
        </div>
      </div>
    </div>
    <div class="boxRight">
      <img
        v-for="(item, index) in rightImgs"
        :src="item.img"
        :key="index"
        alt=""
        class="img pointer"
        @click="handleRightImg(item)"
      />
    </div>
  </div>
</template>

<script>
import advertising from "~gbk/components/index/home/advertising";
export default {
  name: "activity",
  components: { advertising },
  data() {
    return {
      activeName: "3",
      tags: [
         {
          label: "行业动态",
          name: "3",
        },
        {
          label: "政策法规",
          name: "4",
        },
      ],
      leftList: [],
      leftLoading: false,
      centerLoading: false,
      centerList:[],
      rightList: [],
      rightLoading: false,
      advertising: {},
      rightImgs: [
        // {
        //   img:
        //     "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/apply.png",
        //   path: "/memberShip",
        // },
        
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/dangerOurce.png",
          path: "/downloadSection",
        },
        // {
        //   img:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/download.png",
        //   // path:"http://www.chemright.cn/",
        // },
        {
          img:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/safetyInquire.png",
          path: "http://hxp.nrcc.com.cn/hc_safe_info_search.html"
        }
      ],
    };
  },
  computed: {
    defaultImg() {
      let img = "";
      switch (this.activeName) {
        case "3":
          img =
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active2.jpg";
          break;
        case "4":
          img =
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active.jpg";
          break;
        case "1":
          img =
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active1.jpg";
          break;
        case "7":
          img =
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active3.jpg";
          break;
      }
      return img;
    },
  },
  created() {
    this.getLeftList();
    this.getCenterList();
    this.getAdvertisingList();
  },
  methods: {
    handleRightImg(val) {
      let path = val.path;
      if(!path) return
      if (val.path === "/memberShip" && !this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      }
      this.$router.push(path);
      if (val.path === 'http://www.chemright.cn/') {
        window.open(path)
      }
      if (val.path === 'http://hxp.nrcc.com.cn/hc_safe_info_search.html') {
        window.open(path)
      }
    },
    async getLeftList() {
      let params = {
        content_type: this.activeName,
        source: this.PJSource,
        nopage: 0,
        limit: 4,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      this.leftLoading = true;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      this.leftLoading = false;
      if (res.success) {
        this.leftList = res.data;
      }
    },
     async getCenterList() {
      this.centerLoading = true
      let params = {
        source: this.PJSource,
        nopage: 0,
        start: 0,
        limit: 6
      }
      let res = await this.$store.dispatch("API_company/checkNewCompanyList", params);
      this.centerLoading = false
      if (res.success) {
        res.data.forEach((item) => {
          item.business_classification_list = this.handleData(
            item.business_classification_info
          );
        });
        this.centerList = res.data.concat(res.data);
      }
    },
    // 转数据
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
    goDetail(item) {
      this.$router.push({
        path: "/newsDetail",
        query: { id: item.id },
      });
    },
    handleClickCenterTab(e) {
      this.getLeftList();
    },
    handleCenterMore() {
      this.$router.push({
        path: "/companyCatalog"
      });
    },
    handleMore() {
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: this.activeName * 1,
        },
      });
    },
    // 点击名字跳转到个人画像
    goCompanyDetail(item) {
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: item.company_id
          })
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    async getAdvertisingList() {
      let params = {
        language: this.LOCALE === "en" ? 1 : 2,
        source: this.PJSource,
        serial_no: "1",
      };
      let res = await this.$store.dispatch(
        "API_index/getAdvertisingList",
        params
      );
      if (res.success) {
        if (res.data.length > 0) {
          res.data[0].extend = JSON.parse(res.data[0].extend);
        }
        this.advertising = res.data[0];
      }
    },
  },
};
</script>

<style scoped lang="less">
.box {
  margin-top: 42px;
  display: flex;
  justify-content: space-between;
  .boxLeft {
    width: 800px;
    .boxLeftTitle {
      font-weight: 900;
      color: #345292;
      font-size: 24px;
      padding-bottom: 15px;
    }
    .top {
      padding-top: 4px;
      width: 100%;
      height: 46px;
      overflow: hidden;
      position: relative;
      .titleBox {
        padding-left: 16px;
        padding-right: 16px;
      }
      /deep/ .el-tabs__nav-wrap::after {
        background-color: #dcdfe6;
        border-bottom: 2px;
      }
      /deep/ .el-tabs__item {
        font-size: 18px;
        font-weight: 800;
        color: #7d8695;
      }
      /deep/ .el-tabs__item {
        font-size: 18px;
        font-weight: 800;
        color: #909399;
      }
      /deep/ .el-tabs__item.is-active {
        color: #1f292e;
      }
      /deep/ .el-tabs__active-bar {
        background-color: #7EC9F1;
      }
      .moreBox {
        position: absolute;
        right: 0;
        top: 10px;
        padding: 5px 19px;
        color: #909399;
        .more {
          font-size: 14px;
          color: #909399;
        }
      }
    }
    .bottonContainer {
      min-height: 333px;
      position: relative;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      .listLeft {
        width: 364px;
        .imgBox {
          width: 352px;
          height: 175px;
          //background: #cccccc;
          .img {
            width: 352px;
            height: 175px;
          }
        }
        .itemTitle {
          padding: 8px 0 8px;
          font-size: 16px;
          color: #1f292e;
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          max-width: 364px;
        }
        .date {
          margin-top: 6px;
          color: #7d8695;
          font-size: 14px;
        }
        .itemContent {
          color: #7d8695;
          font-size: 14px;
        }
        .moreBox {
          margin-top: 14px;
          display: flex;
          align-items: center;
          width: 80px;
          .moreText {
            color: #909399;
            margin-right: 6px;
          }
          .moreIconBox {
            width: 16px;
            height: 16px;
            background: #2e76ee;
            border-radius: 8px;
            text-align: center;
            line-height: 16px;
            .moreIcon {
              font-size: 10px;
              color: #ffffff;
            }
          }
        }
      }
      .listRight {
        width: 400px;
        position: relative;
        min-height: 303px;
        .rightItem {
          .itemContent {
            color: #7d8695;
            font-size: 14px;
            line-height: 26px;
            border-bottom: 1px solid #dcdfe6;
            padding-bottom: 13px;
            padding-top: 14px;
            .itemTitle {
              width: 400px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }
          }
          .date {
            font-size: 14px;
            color: #7d8695;
            text-align: right;
          }
        }
        .rightItem:nth-child(2) .itemContent {
          padding-top: 0;
        }
      }
    }
  }

  .boxCenter {
    width: 810px;
    height: 370px;
    background: #FFFFFF;
    box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    border: 1px solid #DCDFE6;
    .title {
      width: 810px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      align-items: flex-end;
      padding-top: 21px;
      padding-left: 24px;
      padding-bottom: 20px;
      border-bottom: 1px solid #DCDFE6;
      .topBox {
        display: flex;
        align-items: flex-end;
        font-weight: 600;
        color: #345292;
          .pillar {
              width: 10px;
              height: 63px;
              background: #7EC9F1;
              margin-right: 20px;
          }
      }
    }

    .more {
        width: 80px;
        height: 30px;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;
        color: #909399;
        font-size: 14px;
    }


    .tableHead {
      margin-top: 13px;
      height: 35px;
      background: #FFF6F6;
      font-weight: 500;
      display: flex;
      align-items: center;

      .table1 {
        margin-left: 25px;
        width: 210px;
        color: #909399;
      }

      .table2 {
        width: 407px;
        color: #909399;
      }

      .table3 {
        color: #909399;
      }
    }

    .list {
      width:810px;
      position: relative;
      padding: 15px;
      padding-right: 0;
      display: flex;
      flex-wrap: wrap;
      .item_newPeople {
        display: flex;
        width: 390px;
        margin-bottom: 13px;
        .img{
          width:80px;
          height:80px
        }
        .right_con{
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding-left: 12px;
          width: 300px;
          .company{
            width: 300px;
            height: 20px;
            font-size: 14px;
            font-weight: bold;
            color: #666666;
            line-height: 20px;
          }
          .dizhi, .time{
            max-width: 200px;
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #909399;
            line-height: 17px;
          }
          .tags{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .item{
              margin-right: 12px;
            }
          }
        }
      }
    }

    .img-wrap {
      width: 498px;
      height: 225px;
      position: relative;

      .icon {
        position: absolute;
        top: 5px;
        left: -3px;
      }

      .bannerInner {
        height: 100%;
        background-size: 100% 100%;
        position: relative;

        .bannerBtn {
          position: absolute;
          padding: 0px 20px;
          border: 1px solid;
          border-radius: 4px;
          transform: translate(50%, 50%);

          &.btnStyle1 {
            border-radius: 0px;
          }

          &.btnStyle2 {
            border-radius: 4px;
          }

          &.btnStyle3 {
            border-radius: 999px;
          }
        }
      }
    }
  }

  .boxRight {
    width: 364px;
    height: 370px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    img {
      margin-bottom: 20px;
    }
  }
  .pointer {
    cursor: pointer;
  }
}
.color909399 {
  color: #909399;
}
</style>