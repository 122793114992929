<template>
    <div class="box">
        <div class="title">
            <div class="topBox">
                <!-- <div class="pillar"></div> -->
                <span>联盟合作协会介绍</span>
            </div>
            <div class="cursor more" @click="handleMore">
                <span class="moreFont">全部</span>
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <div class="item_box">
            <div class="item" v-for="(item,i) in list" :key='i' :style="(i+1)%4 == 0 ? 'margin-right:0':''" >
                <el-image
                    @click="open_web(item)"
                    class="cursor"
                    style="width: 290px; height:125px"
                    :src="item.logo"
                    fit="corver">
                </el-image>
                <div class="tit cursor textOverflow" @click="handle_item_more(item)">
                    <span v-if="item.fullname_zh">{{  item.fullname_zh | fullTextFormat(100) }}</span>
                    <span v-else-if="item.fullname_en">{{  item.fullname_en | fullTextFormat(100) }}</span>
                    <span v-else >-</span>
                </div>
            </div>
            <noDataImg v-if="!loading && list.length === 0"></noDataImg>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            loading:false,
            list:[],
            eye_img:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/eye.png",
            address:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/address.png"
        }
    },
    mounted(){
        this.getList()
    },
    methods:{
        async getList(){
            this.loading=true
            let params={
                start:0,
                limit:8,
                source:this.PJSource,
                user_id:this.USER_INFO.id
            }
            this.loading=false
            let res = await this.$store.dispatch("API_index/association_alliance", params);
            if (res.success) {
                this.list=res.data
            }
        },
        handleMore(){
            this.$router.push({
                path: "/associationList",
            });
        },
        open_web(item){
            if(item.website.indexOf('http')!=-1){
                window.open(item.website)
            }else{
                window.open('http://'+item.website)
            }

        },
        handle_item_more(item){
            this.$router.push({
                path: "/associationDetail",
                query: {
                    parameter: this._encode({
                        id:item.id,
                    }),
                },
            });
        }
    }
}
</script>
<style lang="less" scoped>
    .box{
        .title {
            display: flex;
            justify-content: center;
            position: relative;
            font-size: 24px;
            align-items: flex-end;
            padding-top: 48px;
            margin-bottom: 40px;
            .topBox {
            display: flex;
            align-items: flex-end;
            font-weight: 800;
            color: #fff;
                .pillar {
                    width: 10px;
                    height: 63px;
                    background: #7EC9F1;
                    margin-right: 20px;
                }
            }
        }
        .more {
            position: absolute;
            right: 0;
            width: 80px;
            height: 30px;
            border-radius: 15px;
            text-align: center;
            line-height: 30px;
            color: #909399;
            font-size: 14px;
        }
        .item_box{
            position: relative;
            min-height: 300px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .item{
                width: 290px;
                height: 180px;
                background: #FFFFFF;
                margin-right: 13px;
                margin-bottom: 32px;
            }
            .item:nth-child(4){
                margin-left:0;
            }
            .tit{
                margin: auto;
                padding-top: 14px;
                word-break: break-all;
                width: 242px;
                font-size: 16px;
                font-weight: bold;
                color: #1F292E;
                text-align: left;
            }
        }
        .bottom_loc{
            cursor: pointer;
            border-top: 1px solid  #E9E9E9;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
            span{
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #345292;
                line-height: 20px;
                padding-right: 8px;
            }
            i{
                font-size: 14px;
                font-weight: 400;
                color: #345292;
            }

        }

    }
</style>
