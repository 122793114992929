import { render, staticRenderFns } from "./EventNotification.vue?vue&type=template&id=308f5084&scoped=true"
import script from "./EventNotification.vue?vue&type=script&lang=js"
export * from "./EventNotification.vue?vue&type=script&lang=js"
import style0 from "./EventNotification.vue?vue&type=style&index=0&id=308f5084&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308f5084",
  null
  
)

export default component.exports