<template>
  <div class="box">
    <div class="boxLeft">
      <div>
        <span class="title">校企合作</span>
        <span class="message" v-if="leftList.length">您有一条新的招聘信息请查收！</span>
      </div>
      <div class="pointer more" @click="handleMore">
        <span class="moreFont">更多</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="list" v-loading="leftLoading">
        <div class="item" v-for="(item, index) in leftList" :key='index' :class="(index+1)%2 == 1 ? 'white':'gray'">
          <div class="item_left">
            <div class="adress pointer" @click="handleLeft(item.id)">{{
                item.name
              }}
            </div>
          </div>
          <div class="item_right"><span class="pointer" @click="handleLeft(item.id)">{{ item.city_name_en | priorFormat(item.city_name_zh, LOCALE) }} | {{item.experience | experienceFormat(LOCALE)}} | {{item.level_require | levelTypeFormat(LOCALE)}}</span></div>
          <div class="corporate textOverflow">{{ item.name_en | priorFormat(item.name_zh, LOCALE) }}</div>
          <div class="time date textOverflow">{{ item.created_time | secondFormat("LL") }}</div>
          <span class="ranking">{{index+1}}</span>
        </div>
        <noDataImg v-if="!leftLoading && leftList.length === 0"></noDataImg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "schoolCooperation",
  props: {
    advertising3: {
      type: Object,
      default: null
    }
  },
  filters: {
    strings() {
      return '11111'
    }
  },
  data() {
    return {
      icons: [
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon1.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon2.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon3.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon4.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon5.png",
      ],
      banner: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/activitybanner.png",
      leftList: [],
      leftLoading: false,
    }
  },
  mounted() {
    this.getLeftList()
  },
  methods: {
    async getLeftList() {
      // 获取职位推荐
      this.leftLoading = true;
      let parmas = {
        start: 0,
        limit: 5,
        is_hot: 1,
        source: this.PJSource,
      }
      let data = await this.$store.dispatch("API_position/getJobPositionList", parmas);
      this.leftLoading = false;
      if (data.success) {
        this.leftList = data.data;
      }
    },
    ////点击名字跳转到个人画像
    goCompanyDetail(item) {
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: item.company_id
          })
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    handleLeft(id) {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id
          }),
        },
      });
    },
    handleMore() {
      this.$router.push({
        path: "/positionList"
      });
    },
    handleRightItem(item) {
      this.$router.push({
        path: "/detail",
        query: { id: item.id },
      });
    },
  }
}
</script>

<style scoped lang="less">
 .gbk_index_more{
   cursor: pointer;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #909399;
    line-height: 20px;
  }


.box {
  display: flex;
  width: 100%;
  min-height: 200px;
  padding-bottom: 38px;
  .boxLeft {
    width: 1200px;
    margin-right: 84px;
    position: relative;
    
    .title {
      color: #4f689f;
      font-size: 24px;
      margin-right: 16px;
      font-weight: 800;
    }

    .message {
      font-size: 14px;
      color: #909399;
    }

     .more {
      position: absolute;
      right: 0;
      top: 0;
      width: 80px;
      height: 33px;
      border-radius: 15px;
      text-align: center;
      line-height: 33px;
      color: #909399;
      font-size: 14px;
    }


    .list {
      position: relative;
      width: 1200px;
      padding-top: 24px;
      min-height: 220px;

      .item {
        display: flex;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        height: 54px;
        line-height: 54px;
        padding: 0 12px;
        div{
          width: 342px;
        }
        .item_left {
          color: #1F292E;
          font-weight: 600;
          display: flex;
        }
        .time{
          width: 150px;
        }
        

        .img {
          margin-right: 10px;
        }

        .adress {
          color: #1F292E;
          margin-right: 10px;
          width: 220px;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .item_right {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #027FFF;
        }
        .ranking{
          position: absolute;
          right: -6px;
          bottom: -8px;
          font-weight: 600;
          font-size: 48px;
          color: #345292;
        }

        .date {
          color: #909399;
        }
      }
      .item.white{
        background: #FFFFFF
      }
      .item.gray{
        background: #F2F6FF;
      }
    }
  }
 
}

.pointer {
  cursor: pointer;
}

.color909399 {
  color: #909399;
}
</style>
