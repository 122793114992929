<template>
  <div class="box">
    <div class="title">
      <div class="topBox">
        <span>视频课程</span>
      </div>
      <div class="pointer more" @click="handleMore">
        <span class="moreFont">更多</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="list">
      <div class="item" v-for="(item, index) in dataList" :key="index" :style="(index+1)%4 == 0 ? 'margin-right:0':''">
        <div class="imgBox pointer img-wrap">
          <el-image :src="item.cover_url" fit="cover" class="img"></el-image>
          <div class="mark" @click="handleContent(item)">
            <i class="el-icon-video-play cursor"></i>
          </div>
        </div>
        <div class="contentBox">
          <div class="contentTitle">{{ item.name | textFormat }}</div>
          <!-- <div class="content"><span class="pointer" @click="handleContent">{{ item.content | fullTextFormat(35) }}</span></div> -->
        </div>
      </div>
      <noDataImg v-if="!loading && dataList.length === 0"></noDataImg>
    </div>
    <videoPlay ref="vidoePlay"></videoPlay>
  </div>
</template>

<script>
import videoPlay from '@/baseComponents/videoPlay'

export default {
  name: "education",
  components: {
    videoPlay
  },
  data() {
    return {
      dataList: [],
      loading: false
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.loading = true
      let params = {
        source: this.PJSource,
        start: 0,
        limit: 4,
        home_flag:1
      }
      let res = await this.$store.dispatch('API_company/association_video_List', params)
      this.loading = false
      if (res.success) {
        this.dataList = res.data
      }
    },
    handleMore() {
      this.$router.push('/college')
    },
    handleContent(item) {
      if (this.IS_LOGIN) {
        let auth = this.$store.state.baseStore.auth.USER_AUTH.menu
        // 判断用户是否有权限
        let is_exist = auth.find((item)=>{
            return item.identifier == "dga:tra"
        })
        if(!is_exist) return this.$message.error("您好，您当前尚未开通培训学院模块权限，如需开通请联系+86-21-62886580或者gbk-china@gbk-ingelheim.cn")

        this.$router.push({
            path:"/college_detail",
            query:{
                parameter:this._encode({
                    video_id:item.id,
                }),
            },
        })
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.$router.push({
                path:"/",
            })

          },
        });
      }


    }
  }
}
</script>

<style scoped lang="less">
.box {
  margin-top: 32px;
  .title {
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 24px;
    align-items: flex-end;
    .topBox {
      display: flex;
      align-items: flex-end;
      font-weight: 800;
      color: #333333;
      .pillar {
        width: 10px;
        height: 63px;
        background: #7EC9F1;
        margin-right: 20px;
      }
    }

  }

  .more {
    position: absolute;
    right: 0;
    width: 80px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
    color: #909399;
    font-size: 14px;
  }

  .list {
    position: relative;
    min-height: 300px;
    margin-top: 26px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .item {
      width: 290px;
      margin-right: 13px;
      .imgBox {
        width: 290px;
        height: 174px;
        overflow: hidden;
        // border-radius: 10px 10px 0px 0px;
      }
      .img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        // border-radius: 10px 10px 0px 0px;
      }
      .contentBox {
        .contentTitle {
          margin-top:10px;
          font-size: 16px;
          font-weight: bold;
          color: #4C4C4C;
          line-height: 22px;
          width: 290px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }

        .content {
          margin-top: 6px;
          color: #1F292E;
          width: 290px;
          word-wrap: break-word;
        }
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.img-wrap {
  position: relative;

  .mark {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 174px;
    line-height: 174px;
    text-align: center;

    .el-icon-video-play {
      font-size: 30px;
      color: #aaa;
    }
  }

  .mark:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  .mark:hover .el-icon-video-play {
    color: #fff;
  }
}
</style>
