<template>
  <div class="box">
    <div class="right">
      <div class="titBox">
        <div class="title">联盟主要成员</div>
      </div>
      <div class="content">
        <el-carousel indicator-position="outside" trigger="click" height="290px" v-if="dataList.length">
          <el-carousel-item v-for="(item,index) in dataList" :key="index">
            <div class="item" v-for="(ee,vv) in item" :key="vv">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="ee.logo"
                  fit="scale-down" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <noDataImg v-else />
      </div>
    </div>
  </div>
</template>

<script>
import {splitArr} from "~/baseUtils";

export default {
  name: "cooperation",
  data() {
    return {
      loading:false,
      dataList:[],
    };
  },
  created() {
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList(){
      this.loading = true
      let params={}
      params.nopage=1
      params.source=this.PJSource
      let res=await this.$store.dispatch("API_index/getRecommentCompanyList",params)
      if(res.success){
        let data=res.data
        this.dataList=splitArr(res.data,6)
        this.loading = false
      }
    }
  }
};
</script>

<style scoped lang="less">
.box {
  padding-bottom: 40px;
  .right {
    width: 100%;
    .titBox{
      display:flex;
      justify-content: center;
      position: relative;
      align-items: center;
      margin-bottom: 30px;
      .huan_H{
        position: absolute;
        right: 0;
        cursor: pointer;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #027FFF;
        line-height: 20px;
        display: flex;
        align-items: center;
        i{
          margin-right:5px;
        }
      }
    }
  }

  .title {
    font-weight: 800;
    color: #192F59;
    font-size: 24px;
  }
}

.imgSilder {
  width: 100%;

  /deep/ .el-carousel {
    width: 100%;
  }

  /deep/ .el-carousel__arrow {
    display: none;
  }

  /deep/ .el-carousel__container {
    height: 60px;
    width: 100%;
  }

  .img-wrap {
    width: 220px;
    height: 60px;
    background: #fff;
    margin-right: 25px;

    .item-img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .img-wrap:nth-child(5) {
    margin-right: 0;
  }
}
.content{
  position: relative;
  min-height:200px;
  /deep/ .el-carousel__item{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .item{
    width: 388px;
    height: 134px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    border: 1px solid #E9E9E9;
    margin-right: 17px;
    margin-bottom: 22px;
    &:nth-child(3n){
      margin-right: 0;
    }
  }
}
</style>
