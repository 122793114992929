<template>
  <div class="container">
    <carouselBanner paddingTop="24%" :bannerType="1" :showSearch="1"/>
    <div class="content_area">
      <activity />
    </div>
    <div class="EventNotification">
        <div class="content_area">
          <EventNotification></EventNotification>
        </div>
    </div>
    <div class="education">
      <div class="content_area">
        <education />
      </div>
    </div>
    <div class="schoolCooperation">
      <div class="content_area">
        <schoolCooperation/>
      </div>
    </div>
    <div class="AssociationDes">
        <div class="content_area">
          <AssociationDes></AssociationDes>
        </div>
    </div>
    <!--  -->
    <div class="cooperation">
      <div class="content_area">
        <cooperation />
      </div>
    </div>
  </div>
</template>

<script>
import carouselBanner from '@/baseComponents/carouselBanner'
import activity from '~gbk/components/index/home/activity'
import schoolCooperation from '~gbk/components/index/home/schoolCooperation'
import education from '~gbk/components/index/home/education'
import risks from '~gbk/components/index/home/risks'
import EventNotification from '~gbk/components/index/home/EventNotification'
import AssociationDes from '~gbk/components/index/home/AssociationDes'


import cooperation from '~gbk/components/index/home/cooperation'
export default {
  metaInfo:{
    title:'首页'
  },
  components: {
    carouselBanner,
    activity,
    schoolCooperation,
    education,
    risks,
    cooperation,
    EventNotification,
    AssociationDes
  },
  data() {
    return {

    };
  },
  computed: {},
  created() {
  },
  methods: {
    goApply() {
      this.$router.push('/memberShip')
    }
  },
};
</script>
<style scoped lang="less">
  .container {
    background: #FFFFFF;
    .apply {
      margin-top: 32px;
      width: 100%;
      height: 120px;
      background: #D8D8D8;
      line-height: 120px;
      color: #909399;
      font-size: 40px;
      text-align: center;
      cursor: pointer;
    }
    .education {
      width: 100%;
      // background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/homeBack.png");
      height: 315px;
      background-size: 100% 100%;
    }
    .cooperation {
      margin-top: 32px;
      width: 100%;
      background: white;
    }
    .EventNotification{
      margin-top: 32px;
      width: 100%;
      height: 439px;
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/2pBg.png");
      background-size: 100% 100%;
    }
    .schoolCooperation{
      height: 421px;
      padding-top: 32px;
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/Shape8.png");
      background-size: 100% 100%;
    }
    .AssociationDes{
      width: 100%;
      height: 585px;
      background-color:#F3FBFF;
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/bg.png");
      background-size: 100% 100%;
    }
    .pointer {
      cursor: pointer;
    }
  }
</style>
